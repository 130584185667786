<template>
  <div class="card card-custom gutter-b p-5">
    <div class="py-2">
      <b-button class="float-right" variant="success" @click="showAddModal">
        Tambah Akun
      </b-button>
    </div>
    <div style="overflow-x: auto; overflow-y: hidden" class="pt-2">
      <table
        id="example"
        class="table table-striped table-bordered"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th>No</th>
            <th style="width: 250px">Nama Peserta</th>
            <th>Jenis Kelamin</th>
            <th>NIP</th>
            <th style="width: 300px">DPMPTSP</th>
            <th>Bagian</th>
            <th>Email</th>
            <th>Kartu Identitas</th>
            <th style="width: 90px">Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in peserta" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ row.name }}</td>
            <td>{{ row.jenis_kelamin }}</td>
            <td>{{ row.username }}</td>
            <td>{{ row.id_ptsp == null ? "" : checkPTSP(row.id_ptsp) }}</td>
            <td>{{ row.bagian == null ? "" : checkBagian(row.bagian) }}</td>
            <!-- <td>{{ row.bagian }}</td> -->
            <td>{{ row.email }}</td>
            <td class="text-center" ><b-button variant="primary" v-b-tooltip.hover
              title="Lihat Kartu Identitas" @click="lihatIdentias(row.ijazah)"><i class="fa fa-address-card"></i></b-button></td>
            <td>
              <b-button
                @click="$router.push('profil-peserta/' + row.id)"
                size="sm"
                variant="success"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Detail Peserta"
                ><i class="flaticon-eye"></i
              ></b-button>
              <b-button
                @click="showModal(row.id)"
                size="sm"
                variant="warning"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Edit Peserta"
              >
                <i class="flaticon-edit"></i>
              </b-button>
              <b-button
                @click="deleteData(row.id)"
                size="sm"
                variant="danger"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Hapus"
              >
                <i class="flaticon2-trash"></i>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal id="add-peserta-modal" title="Tambah Peserta" hide-footer>
      <form>
        <div class="form-group">
          <label for="name">Nama lengkap</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="form.name"
          />
        </div>
        <div class="form-group">
          <label for="username">NIP</label>
          <input
            type="text"
            class="form-control"
            id="username"
            v-model="form.username"
            v-on:input="validateNumericInput"
          />
        </div>
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="form.email"
            @input="validateEmail"
            ref="emailInput"
          />
          <small v-if="emailError" class="text-danger">{{ emailError }}</small>
        </div>
        <div class="form-group">
          <label for="jenis_kelamin">Jenis kelamin</label>
          <s-select
            :options="jenisKelamin"
            :reduce="(data) => data.value"
            label="text"
            placeholder="Pilih jenis kelamin"
            v-model="form.jenis_kelamin"
          ></s-select>
        </div>
        <div class="form-group">
          <label for="id_ptsp">DPMPTSP</label>
          <s-select
            :options="listPTSP"
            :reduce="(data) => data.idptsp"
            label="daerah"
            placeholder="Pilih DPMPTSP"
            v-model="form.id_ptsp"
          ></s-select>
        </div>
        <div class="form-group">
          <label for="bagian"
            >Bagian
            <span
              ><i
                v-b-popover.hover.top="
                  'Jika tidak menemukan jabatan yang sesuai, silakan pilih opsi Lainnya'
                "
                title="Informasi"
                class="fa fa-info-circle"
                aria-hidden="true"
                style="color: orange; font-size: 10px"
              ></i></span
          ></label>
          <s-select
            :options="bagian"
            :reduce="(data) => data.value"
            label="text"
            placeholder="Pilih bagian"
            v-model="form.bagian"
          ></s-select>
        </div>

        <div class="form-group" v-if="form.bagian === 'lainnya'">
          <label for="bagian_lainnya">Bagian Lainnya</label>
          <input
            type="text"
            class="form-control"
            id="bagian_lainnya"
            v-model="form.bagian_lainnya"
          />
        </div>
        <div class="form-group">
          <label for="password">Kata sandi</label>
          <div class="input-group">
            <input
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              id="password"
              v-model="form.password_plain"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="toggleShowPassword"
              >
                <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Konfirmasi kata sandi</label>
          <div class="input-group">
            <input
              :type="showConfirmPassword ? 'text' : 'password'"
              class="form-control"
              id="confirmPassword"
              v-model="form.confirmPassword"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="toggleShowConfirmPassword"
              >
                <i
                  :class="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                ></i>
              </button>
            </div>
            <span
              v-if="passwordMismatch"
              class="text-white"
              style="font-size: 12px"
            >
              Kata sandi tidak sesuai
            </span>
          </div>
        </div>
        <div>
          <b-button class="float-right" variant="success" @click="submitData"
            >Tambah</b-button
          >
          <b-button
            class="float-right mr-2"
            variant="danger"
            @click="$bvModal.hide('add-peserta-modal')"
          >
            Batal
          </b-button>
        </div>
      </form>
    </b-modal>
    <b-modal id="edit-peserta-modal" title="Edit Peserta" hide-footer>
      <form>
        <div class="form-group">
          <label for="name">Nama lengkap</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="form.name"
          />
        </div>
        <div class="form-group">
          <label for="username">NIP</label>
          <input
            disabled
            type="text"
            class="form-control"
            id="username"
            v-model="form.username"
            v-on:input="validateNumericInput"
          />
        </div>
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            disabled
            type="email"
            class="form-control"
            id="email"
            v-model="form.email"
          />
        </div>
        <div class="form-group">
          <label for="jenis_kelamin">Jenis kelamin</label>
          <s-select
            :options="jenisKelamin"
            :reduce="(data) => data.value"
            label="text"
            placeholder="Pilih jenis kelamin"
            v-model="form.jenis_kelamin"
          ></s-select>
        </div>
        <div class="form-group">
          <label for="id_ptsp">DPMPTSP</label>
          <!-- {{ listPTSP }} -->
          <!-- {{ form.id_ptsp }} -->
          <s-select
            :options="listPTSP"
            :reduce="(data) => data.idptsp"
            label="daerah"
            placeholder="Pilih DPMPTSP"
            v-model="form.id_ptsp"
          ></s-select>
        </div>
        <div class="form-group">
          <label for="bagian"
            >Bagian
            <span
              ><i
                v-b-popover.hover.top="
                  'Jika tidak menemukan jabatan yang sesuai, silakan pilih opsi Lainnya'
                "
                title="Informasi"
                class="fa fa-info-circle"
                aria-hidden="true"
                style="color: orange; font-size: 10px"
              ></i></span
          ></label>
          <s-select
            :options="bagian"
            :reduce="(data) => data.value"
            label="text"
            placeholder="Pilih bagian"
            v-model="form.bagian"
          ></s-select>
        </div>
        <div class="form-group" v-if="form.bagian === 'lainnya'">
          <label for="bagian_lainnya">Bagian Lainnya</label>
          <input
            type="text"
            class="form-control"
            id="bagian_lainnya"
            v-model="form.bagian_lainnya"
          />
        </div>
        <div class="form-group">
          <label for="password">Kata sandi</label>
          <div class="input-group">
            <input
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              id="password"
              v-model="form.password_plain"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="toggleShowPassword"
              >
                <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
              </button>
            </div>
          </div>
        </div>
        <div>
          <b-button class="float-right" variant="success" @click="submitData">
            Simpan
          </b-button>
          <b-button
            class="float-right mr-2"
            variant="danger"
            @click="$bvModal.hide('edit-peserta-modal')"
          >
            Batal
          </b-button>
        </div>
      </form>
    </b-modal>

    <b-modal id="modal-kartuidentitas" title="Kartu Identitas Peserta" hide-footer size="lg">
      <div class="form-group" v-if="kartu_identitas == null || kartu_identitas == ''">
        Tidak Ada Kartu Identitas!
      </div>
      <div v-else>
        <img width="100%" :src="kartu_identitas" alt="">
        
      </div>
      <div>
        
        <b-button
          class="float-right mr-2"
          variant="danger"
          @click="$bvModal.hide('modal-kartuidentitas')"
        >
          Tutup
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueSelect from "vue-select";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "peserta-bmtk",
  components: {
    "s-select": VueSelect,
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      peserta: [],
      form: {
        name: "",
        username: "",
        email: "",
        id_role: "peserta",
        name_role: "Peserta",
        password_plain: "",
        id_ptsp: "",
        bagian: "",
        jenis_kelamin: "",
        confirmPassword: "",
        bagian_lainnya: "",
        passwordMismatch: false,
      },
      emailError: "",
      listPTSP: [],
      bagian: [
        { value: "kadis", text: "Kepala Dinas" },
        {
          value: "kabidpb",
          text: "Kepala Bidang Perizinan Berusaha/Pejabat Fungsional Madya Bidang Perizinan Berusaha",
        },
        {
          value: "kasiepb",
          text: "Kepala Seksi Bidang Perizinan Berusaha/Pejabat Fungsional Muda Bidang Perizinan Berusaha",
        },
        {
          value: "foffpb",
          text: "Staf Front-office Bidang Perizinan Berusaha",
        },
        {
          value: "boffpb",
          text: "Staf Back-office Bidang Perizinan Berusaha",
        },
        {
          value: "hdeskpb",
          text: "Staf Help Desk Bidang Perizinan Berusaha",
        },
        {
          value: "kabiddal",
          text: "Kepala Bidang Pengendalian Pelaksanaan/Pejabat Fungsional Madya Bidang Pengendalian Pelaksanaan",
        },
        {
          value: "kasiedal",
          text: "Kepala Seksi Bidang Pengendalian Pelaksanaan/Pejabat Fungsional Muda Bidang Pengendalian Pelaksanaan",
        },
        { value: "foffdal", text: "Staf Front-office Bidang Dalaks" },
        { value: "boffdal", text: "Staf Back-office Bidang Dalaks" },
        { value: "hdeskdal", text: "Staf Help Desk Bidang Dalaks" },
        { value: "lainnya", text: "Lainnya" },
      ],
      jenisKelamin: [
        { value: "laki-laki", text: "Laki-Laki" },
        { value: "perempuan", text: "Perempuan" },
      ],
      showPassword: false,
      showConfirmPassword: false,
      kartu_identitas: null
    };
  },
  methods: {
    checkBagian(value) {
      const dataBagian = this.bagian.find((p) => p.value == value);
      return dataBagian ? dataBagian.text : value;
    },
    validateNumericInput() {
      this.form.username = this.form.username.replace(/[^0-9]/g, "");
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.form.email)) {
        this.emailError = "Format email tidak sesuai.";
        return false; // Email tidak valid
      } else {
        this.emailError = "";
        return true; // Email valid
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    getdata() {
      $("#example").DataTable().destroy();
      this.getPtsp();
    },
    loaddata() {
      axios
        .get(this.url + "/users?filter=id_role,=,peserta", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.peserta = response.data.data;
          this.initDatatable();
        });
    },
    validatePassword() {
      if (this.form.password_plain !== this.form.confirmPassword) {
        this.passwordMismatch = true;
        return false;
      } else {
        this.passwordMismatch = false;
        return true;
      }
    },
    submitData() {
      if (!this.validateEmail()) {
        Swal.fire("Gagal!", "Format email tidak sesuai.", "error");
        return;
      }

      const dataToSend = {
        name: this.form.name,
        id_role: this.form.id_role,
        name_role: this.form.name_role,
        bagian:
          this.form.bagian === "lainnya"
            ? this.form.bagian_lainnya
            : this.form.bagian,
        jenis_kelamin: this.form.jenis_kelamin,
        id_ptsp: this.form.id_ptsp,
      };

      if (this.form.id) {
        // Update existing user
        const currentPeserta = this.peserta.find((p) => p.id === this.form.id);

        if (currentPeserta && this.form.username !== currentPeserta.username) {
          dataToSend.username = this.form.username;
        }

        if (currentPeserta && this.form.email !== currentPeserta.email) {
          dataToSend.email = this.form.email;
        }

        // Include password if it's filled in the form
        if (this.form.password_plain) {
          dataToSend.password = this.form.password_plain;
        }
      } else {
        // Validate required fields for new user
        if (
          !this.form.name ||
          !this.form.bagian ||
          !this.form.email ||
          !this.form.jenis_kelamin ||
          !this.form.id_ptsp ||
          !this.form.username
        ) {
          Swal.fire("Gagal!", "Silahkan isi semua data", "error");
          return;
        }

        // Create new user
        if (!this.validatePassword()) {
          Swal.fire("Gagal!", "Kata sandi tidak sesuai.", "error");
          return;
        }
        dataToSend.username = this.form.username;
        dataToSend.email = this.form.email;
        dataToSend.password = this.form.password_plain;
      }

      const url = this.form.id
        ? `${this.url}/users/${this.form.id}`
        : `${this.url}/users`;
      const method = this.form.id ? "put" : "post";
      const successMessage = this.form.id
        ? "Data sukses diperbarui!"
        : "Data berhasil ditambahkan!";

      axios[method](url, dataToSend, {
        headers: {
          xth: this.token,
        },
      })
        .then(() => {
          this.$bvModal.hide(
            this.form.id ? "edit-peserta-modal" : "add-peserta-modal"
          );
          this.resetForm();
          this.getdata();
          Swal.fire("Berhasil!", successMessage, "success");
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage = error.response.data.message;
            if (errorMessage === "Username telah digunakan") {
              Swal.fire("Gagal!", "Username telah digunakan.", "error");
            } else if (errorMessage === "Email telah digunakan") {
              Swal.fire("Gagal!", "Email telah digunakan.", "error");
            } else {
              Swal.fire(
                "Gagal!",
                "Ada masalah saat menambahkan data.",
                "error"
              );
            }
          } else {
            Swal.fire("Gagal!", "Silahkan isi semua data", "error");
          }
        });
    },

    resetForm() {
      this.form = {
        id: null,
        name: "",
        username: "",
        email: "",
        id_role: "peserta",
        name_role: "Peserta",
        password_plain: "",
        id_ptsp: "",
        bagian: "",
        jenis_kelamin: "",
        confirmPassword: "",
      };
    },
    deleteData(id) {
      Swal.fire({
        title: "Apa anda yakin?",
        text: "Data yang dihapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`${this.url}/users/${id}`, {
              headers: {
                xth: this.token,
              },
            })
            .then(() => {
              this.getdata();
              Swal.fire("Dihapus!", "Data berhasil dihapus.", "success");
            })
            .catch(() => {
              Swal.fire("Gagal!", "Ada masalah saat menghapus data.", "error");
            });
        }
      });
    },
    showModal(id) {
      const peserta = this.peserta.find((p) => p.id === id);
      if (peserta) {
        this.form = { ...peserta, username: peserta.username };
        this.form.id_ptsp = parseInt(this.form.id_ptsp);
      }
      this.$bvModal.show("edit-peserta-modal");
    },
    showAddModal() {
      this.resetForm();
      this.$bvModal.show("add-peserta-modal");
    },
    checkPTSP(id) {
      const dataPTSP = this.listPTSP.find((p) => {
        return p.idptsp == id;
      });
      return dataPTSP.daerah;
    },
    getPtsp() {
      axios
        .get(this.url + "/profil?sort=idptsp%20ASC", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.listPTSP = response.data.data;
          this.loaddata();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initDatatable() {
      setTimeout(() => {
        const t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            search: "Pencarian :",
            lengthMenu: "Tampilkan _MENU_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          // order: [[0, "asc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
          dom: "Bfrtip",
          buttons: [
            {
              extend: "excel",
              text: "Export Peserta Bimtek",
              className: "btn btn-success",
              title: "DAFTAR PESERTA BIMTEK",
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6],
                orthogonal: "export",
              },
              filename: function () {
                var today = new Date();
                // var n = d.getTime();
                var date =
                  today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate();

                return "Daftar Peserta Bimtek_" + date;
              },
            },
          ],
        });
        // t.on("order.dt search.dt", function () {
        //   t.column(0, { search: "applied", order: "applied" })
        //     .nodes()
        //     .each(function (cell, i) {
        //       cell.innerHTML = i + 1;
        //     });
        // }).draw();
      }, 300);
    },

    lihatIdentias(ijazah){
      this.kartu_identitas = ijazah;
      this.$bvModal.show("modal-kartuidentitas");
    }

  },
  mounted() {
    this.getdata();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Peserta Bimtek" }]);
  },
};
</script>
